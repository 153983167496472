@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/nunito-sans');

* {
  font-family: 'Nunito Sans', sans-serif;
  font-size: inherit;
  line-height: inherit;
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  @apply h-full scroll-smooth text-base;
  body {
    @apply h-full bg-background text-body;
    font-family: 'Nunito Sans', sans-serif;
  }
}

// ** forms
// Autofill style
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f6f8fb inset !important;
  -webkit-text-fill-color: #333333 !important;
}
.dark {
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #283046 inset !important;
    -webkit-text-fill-color: #b4b7bd !important;
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form-group {
  @apply w-full;
}

label {
  @apply mb-2 inline-block text-left text-xs text-body;
}

// form control
.form-control {
  @apply block h-11 w-full rounded border border-solid border-widget-border bg-widget-background bg-clip-padding
  py-6 px-4 text-lg font-normal
  leading-6 text-body transition-[border-color,_box-shadow] duration-150 ease-in-out placeholder:text-lg placeholder:text-placeholder placeholder:opacity-100
  focus:border-primary focus:bg-white focus:text-body focus:outline-none focus:placeholder:translate-x-3 focus:placeholder:transform
  focus:placeholder:transition-all motion-reduce:transition-none;

  &:focus {
    box-shadow: 0 3px 10px 0 rgba(14, 96, 226, 0.1);
  }
  &:valid:focus {
    box-shadow: 0 3px 10px 0 rgba(14, 96, 226, 0.1);
  }

  &:not(:focus)::placeholder {
    @apply translate-x-0 transform transition-all;
  }

  &::-ms-expand {
    @apply border-0 bg-transparent;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #333333;
  }

  &:disabled,
  &:read-only {
    @apply bg-[#efefef] opacity-100;
  }
}

// input group
.input-group {
  @apply relative flex w-full flex-wrap items-stretch;

  > .form-control,
  > .form-control-plaintext {
    @apply relative mb-0 min-w-0 flex-1;
    width: 1%;
  }

  > .form-control:not(:last-child),
  > .custom-select:not(:last-child) {
    @apply rounded-r-none;
  }

  > .form-control:not(:first-child),
  > .custom-select:not(:first-child) {
    @apply rounded-l-none;
  }

  &:focus-within {
    .form-control,
    .input-group-text,
    .btn {
      @apply border-primary shadow-none;
    }
  }

  &.is-valid {
    .input-group-text {
      @apply border-success;
    }
    &:not(.bootstrap-touchspin):focus-within {
      .input-group-text {
        @apply border-success;
      }
    }
  }

  &:disabled {
    .input-group-text {
      @apply bg-[#efefef] opacity-100;
    }
  }

  > .input-group-append > .btn,
  > .input-group-append > .input-group-text,
  > .input-group-prepend:not(:first-child) > .btn,
  > .input-group-prepend:not(:first-child) > .input-group-text,
  > .input-group-prepend:first-child > .btn:not(:first-child),
  > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    @apply rounded-l-none;
  }

  > .input-group-prepend > .btn,
  > .input-group-prepend > .input-group-text,
  > .input-group-append:not(:last-child) > .btn,
  > .input-group-append:not(:last-child) > .input-group-text,
  > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  > .input-group-append:last-child > .input-group-text:not(:last-child) {
    @apply rounded-r-none;
  }
}

.input-group-prepend,
.input-group-append {
  @apply flex;
}

.input-group-prepend {
  margin-right: -1px;
  .input-group-text {
    @apply border-r-0;
  }
}

.input-group-append {
  margin-left: -1px;
  .input-group-text {
    @apply border-l-0;
  }
  &:not(:last-child) .input-group-text {
    @apply border-r-0;
  }
}

.input-group-text {
  @apply mb-0 flex items-center whitespace-nowrap rounded-lg border
border-solid border-[#d8d6de] bg-card
px-3 text-center text-base font-normal
leading-6 transition-[border-color,_box-shadow] duration-150 ease-in-out;
}

// input group merge
.input-group-merge {
  .form-control {
    &:not(:first-child) {
      @apply border-l-0 pl-0;
    }

    &:not(:last-child) {
      @apply border-r-0 pr-0;
    }

    &.is-valid {
      ~ .input-group-append {
        .input-group-text {
          @apply border-success;
        }
      }
    }
  }
  .input-group-prepend {
    ~ .form-control.is-valid {
      .input-group-text & {
        @apply border-success;
      }
    }
  }
}

// // btn ripple
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  // background-color: rgba(255, 255, 255, 0.4);
}

@keyframes ripple {
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.tabs-trigger[data-state='active'] {
  @apply text-primary;

  .tabs-icon {
    @apply text-primary;
  }
}

.help-tooltip::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid rgba(27, 51, 88);
  top: -6px;
  right: 13px;
  border-radius: 10px;
}
